




















































import { InputSetups } from '@/mixins/input-setups'
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator'
import { timezoneList } from 'piramis-base-components/src/logic/timizone-list'
import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import { successNotification } from "@/includes/services/NotificationService";
import EmptyBoardsInfo from '@/components/EmptyBoardsInfo.vue'

@Component({
  data() {
    return {
      timezoneList,
      EmptyBoardsInfo
    }
  }
})
export default class SetBotTokenToBoard extends Mixins<InputSetups, UseFields>(InputSetups, UseFields) {
  @Prop() btnIcon!: string

  @Prop() btnType!: string

  @Prop() btnClasses!: string | Array<string>

  @Prop() action!: 'create' | 'update'

  isOpen = false

  newBoard = {
    token: '',
    timezone: '',
  }

  setBotTokenToBoard(): void {
    this.isOpen = false
    this.isLoad(true)

    this.$store.dispatch('createBoard', this.newBoard)
      .then((board) => {
        if (this.action === "create") {
          this.$router.push({
            name: 'Common_settings',
            params: {
              id: board.id
            }
          })
            .finally(() => this.isLoad(false))
        } else {
          successNotification(this.$t('bot_token_successfully_changed').toString())
          this.isLoad(false)
        }
      })
  }

  @Emit('isLoad')
  isLoad(bol: boolean): boolean {
    return bol
  }

  handleCancel(): void {
    this.isOpen = false
    this.newBoard = {
      token: '',
      timezone: this.$i18n.locale === 'ru' ? 'Europe/Moscow' : 'UTC',
    }
  }

  created(): void {
    this.newBoard.timezone = this.$i18n.locale === 'ru' ? 'Europe/Moscow' : 'UTC'
  }
}
