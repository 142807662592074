














































































































































































//todo
import { BoardService } from '@/includes/services/BoardService'
import PageTitle from '@/components/PageTitle.vue'
import { InputSetups } from '@/mixins/input-setups'
import { BoardConfig, TestBoardStateCode } from '@/includes/types/Board.types'
import TestStatusInfo from '@/components/TestStatusInfo.vue'
import TestStateMixin from '@/mixins/TestStateMixin'
import { errorNotification, successNotification } from '@/includes/services/NotificationService'
import { GetBoardByIdType } from '@/store/boards/BoardsGettersInterface'
import SetBotTokenToBoard from '@/components/SetBotTokenToBoard/SetBotTokenToBoard.vue'
import Admins from '@/views/board-settings/admins.vue'
import BoardLicenseInformation from '@/components/BotLicenseInformation.vue'

import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import { timezoneList } from 'piramis-base-components/src/logic/timizone-list'
import LanguagePicker from 'piramis-base-components/src/components/LanguagePicker.vue'
import AListCustom from 'piramis-base-components/src/components/AListCustom/AListCustom.vue'
import AListCustomItem from 'piramis-base-components/src/components/AListCustom/AListCustomItem.vue'
import ClipboardCopyMixin from 'piramis-base-components/src/logic/helpers/ClipboardCopyMixin'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'

import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import { snakeCase } from 'lodash'
import { mapGetters } from 'vuex'

@Component({
  components: {
    BoardLicenseInformation,
    Admins,
    PageTitle,
    LanguagePicker,
    TestStatusInfo,
    AListCustom,
    AListCustomItem,
    SetBotTokenToBoard,
    ConfigField,
  },
  data() {
    return {
      snakeCase
    }
  },
  computed: {
    ...mapGetters([ 'getBoardById' ])
  }
})
export default class CommonSettings extends Mixins<UseFields, InputSetups, TestStateMixin, ClipboardCopyMixin>(UseFields, InputSetups, TestStateMixin, ClipboardCopyMixin) {
  getBoardById!:GetBoardByIdType

  editor_max_post_access_time = 0

  editor_hold_time = 0

  boardSettings: Pick<BoardConfig, 'timezone' | 'lang' | 'config' | 'test_state' | 'license_time' | 'license_type'> = {
    config: {
      styles: {},
    },
    lang: 'ru',
    timezone: 'UTC',
    test_state: '' as TestBoardStateCode

  } as Pick<BoardConfig, 'timezone' | 'lang' | 'config' | 'test_state' | 'license_time' | 'license_type'>

  testStateLoading = false

  botChanging = false

  deleteBoard(): void {
    this.$confirm({
      title: this.$t('remove_board_warning').toString(),
      content: this.$t('remove_board_warning_message').toString(),
      okText: this.$t('accept').toString(),
      okType: 'danger',
      cancelText: this.$t('reject').toString(),
      centered: true,
      onOk: () => {
        this.botChanging = true

        BoardService.deleteBoard('tg', {
          board_key: this.$store.state.boardsState.activeBoard!.board,
        })
          .then(() => {
            this.$router.push({
              name: 'Boards'
            })
          })
          .catch(errorNotification)
      }
    })
  }

  getTimezoneList(): Promise<any> {
    return Promise.resolve(timezoneList)
  }

  saveBoardConfig(): void {
    this.isLoading = true

    BoardService.setPostBoardConfig('tg', {
      board_key: this.getBoardById(this.$route.params.id)!.board,
      config: this.boardSettings.config,
      lang: this.boardSettings.lang,
      timezone: this.boardSettings.timezone
    })
      .then((resConfig) => {
        this.$store.commit('set_active_board_config', resConfig)
        successNotification()
        this.fetchConfig()
      })
      .catch(errorNotification)
      .finally(() => this.isLoading = false)
  }

  fetchConfig(): void {
    const data = this.$store.state.boardsState.activeBoard

    if (data) {
      this.boardSettings.config = data.config
      this.boardSettings.lang = data.lang
      this.boardSettings.timezone = data.timezone
      this.boardSettings.test_state = data.test_state
      this.boardSettings.license_time = data.license_time
      this.boardSettings.license_type = data.license_type
    }

  }

  async postBoardTestState(): Promise<void> {
    this.buttonDisabled = true
    this.testStateLoading = true

    try {
      await BoardService.testPostBoard('tg', { board_key: this.getBoardById(this.$route.params.id)!.board })
      this.fetchConfig()

      this.buttonDisabled = false
    } catch (error: any) {
      if (error.response.status === 429) {
        this.buttonDisabled = true

        setTimeout(() => this.buttonDisabled = false, 15000)
        errorNotification('Попробуйте проверить статус позже')
      } else {
        errorNotification(error)
        this.buttonDisabled = false
      }
    } finally {
      this.testStateLoading = false
    }
  }

  created(): void {
    this.fetchConfig()

    if (this.boardSettings.test_state === TestBoardStateCode.None) {
      this.postBoardTestState()
    }
  }
}
