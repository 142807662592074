var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-button',{class:Array.isArray(_vm.btnClasses) ? _vm.btnClasses.join(' ') : _vm.btnClasses,attrs:{"type":_vm.btnType ? _vm.btnType : 'default',"icon":_vm.btnIcon ? _vm.btnIcon : ''},on:{"click":function($event){_vm.isOpen = true}}},[_vm._t("default"),_c('a-modal',{attrs:{"centered":"","title":_vm.action === 'create' ? _vm.$t('add_board_modal_title').toString() : _vm.$t('update_board_token').toString(),"ok-text":_vm.$t('resolve_button_title'),"cancel-text":_vm.$t('reject_button_title'),"ok-button-props":{
      props: { disabled: !_vm.newBoard.token }
    }},on:{"ok":_vm.setBotTokenToBoard,"cancel":_vm.handleCancel},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('text-input',{staticClass:"popup__bot-token",attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.newBoard,
          'prefix': 'board_',
          'key': 'token',
          'validation': 'required',
          'customHelpMessage': _vm.EmptyBoardsInfo
        }
      }}}),(_vm.action === 'create')?_c('select-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.newBoard,
          'key': 'timezone',
          'prefix': 'board_',
          'options': _vm.timezoneList,
          'clearable': false
        }
      }}}):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }