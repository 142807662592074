












































import BotLicenseButton from './BotLicenseButton.vue'

import AListCustom from 'piramis-base-components/src/components/AListCustom/AListCustom.vue'
import AListCustomItem from 'piramis-base-components/src/components/AListCustom/AListCustomItem.vue'

import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import moment from 'moment'

@Component({
  components: {
    AListCustom,
    AListCustomItem,
    BotLicenseButton
  },
})
export default class BoardLicenseInformation extends Vue {
  @Prop() botLicenseTime!:string

  get licenseExpireDate():string {
    moment.locale(this.$i18n.locale)
    return moment(this.botLicenseTime).format('LLL')
  }

  get boardLicenseExpireStateTitle():string {
    if (!this.$store.getters.hasBoardLicense) {
      return this.$t('license_buy').toString()
    } else if (this.$store.getters.boardLicenseExpiresSoon) {
      return this.$t('button_license_prolongate').toString()
    } else {
      return this.$t('license_expires').toString()
    }
  }

  get boardLicenseTypeTitle():string {
    if (this.$store.getters.hasBoardLicense) {
      return this.$store.getters.boardLicenseType
    } else {
      return this.$t('license_expired').toString()
    }
  }

}
